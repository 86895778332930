<template>
  <div class="px-5">
    <Form
      title="Editar subcategoría"
      ref="subcategoryForm"
      :deletable="true"
      :loading.sync="loading"
      @save="save"
      @toggleVisibility="toggleVisibility"
    />
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import Form from "./Form.vue"
export default {
  components: {
    Form
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    toggleVisibility() {
      this.$store
        .dispatch("global/changeStatus", {
          payload: {
            id: this.$route.params.id,
            status: !this.$refs.subcategoryForm.visible ? 1 : 0
          },
          route: "/master/subcategory",
          module: "subcategory"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification("master-status", response.code, this.$snotify)
          })
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    save() {
      let form = this.$refs.subcategoryForm.form
      this.loading = true

      this.$store
        .dispatch("global/update", {
          payload: {
            id: form.id,
            name: form.name
          },
          route: "/master/subcategory/update",
          module: "subcategory"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification("master-update", response.code, this.$snotify)
          })
          this.loading = false

          if ([200, 201].includes(response.code)) {
            this.$router.push("/subcategorias")
          }
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    deletePlayer() {
      this.$dialog
        .confirm({
          title: "¿Estás seguro?",
          text: `Esta acción eliminará permanentemente la subcategoría.`,
          icon: "question",
          escToClose: true,
          cancelButtonText: "Cancelar",
          okButtonText: "Ok"
        })
        .then((result) => {
          if (result.isOk) {
            this.$store
              .dispatch("global/changeStatus", {
                payload: {
                  id: this.$refs.subcategoryForm.form.id,
                  status: 2
                },
                route: "/master/subcategory",
                module: "subcategory"
              })
              .then((response) => {
                notify().then(({ notification }) => {
                  notification(
                    "master-status-delete",
                    response.code,
                    this.$snotify
                  )
                })
                if (response.code === 200) this.$router.push("/subcategorias")
              })
              .catch((error) => {
                notify().then(({ notification }) => {
                  notification("error", error.code, this.$snotify)
                })
              })
          }
        })
    },
    getItem() {
      this.$store
        .dispatch("global/getItem", {
          route: "/master/subcategory",
          module: "subcategory",
          id: this.$route.params.id
        })
        .then((response) => {
          this.form = response.data
          this.$refs.subcategoryForm.form = this.form
          this.$refs.subcategoryForm.visible =
            response.data && response.data.active === 1 ? true : false
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    }
  },
  mounted() {
    this.getItem()
  }
}
</script>
